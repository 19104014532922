<template>
  <b-container v-if="invoice" class="mobile-friendly">
    <b-row>
      <div class="button-section mx-auto d-flex flex-wrap">
        <b-col class="d-flex py-2" cols="12" sm="auto" order="0" order-md="1">
          <b-button
            variant="primary"
            :disabled="preventPayment"
            class="my-auto"
            :size="$mq"
            aria-label="Pay Invoice Button"
            @click="payInvoice"
          >
            <feather-icon type="dollar-sign" />
            <span v-if="this.$mq !== 'sm'">
              Pay Invoice
            </span>
            <span v-else>
              Pay
            </span>
          </b-button>
          <b-button
            variant="primary"
            class="ml-4 my-auto"
            :size="$mq"
            aria-label="Download Button"
            @click="download"
          >
            <feather-icon type="download" />
            Download
          </b-button>
        </b-col>
        <b-col class="px-0 py-2" cols="12" sm="auto" order="1" order-md="0">
          <b-button
            variant="link"
            :size="$mq"
            to="/invoices"
            aria-label="Back Button"
          >
            <feather-icon type="chevron-left" />
            Back
          </b-button>
        </b-col>
      </div>
    </b-row>
    <b-row>
      <div class="invoice-container mx-auto d-inline-flex flex-wrap">
        <!-- Pay to -->
        <b-col class="p-0" cols="12" md="6" order="5" order-md="0">
          <div v-if="payTo" class="x-pad d-flex h-100">
            <div class="my-auto d-flex">
              <div class="mr-4">
                Pay to
              </div>
              <div>
                <strong> {{ payTo.billingName }} </strong>
                <div class="pt-1">
                  <div>{{ payToAddress.line1 }}</div>
                  <div>{{ payToAddress.line2 }}</div>
                  <div>
                    {{ payToAddress.city }},
                    {{ payToAddress.stateProvinceRegion }}
                    {{ payToAddress.zipPostalCode }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Invoice title and info -->
        <b-col class="p-0" cols="12" md="6" order="0" order-md="1">
          <div class="invoice-info d-flex flex-column h-100">
            <div class="invoice-title-text mb-2">
              Invoice
            </div>
            <table>
              <tr>
                <td>Invoice Number</td>
                <td class="text-right">{{ invoice.invoiceNumber }}</td>
              </tr>
              <tr>
                <td>Invoice Date</td>
                <td class="text-right">{{ formatDate(invoice.createdAt) }}</td>
              </tr>
              <tr>
                <td><strong>Due Date</strong></td>
                <td class="text-right"><strong>{{ formatDate(invoice.dueDate) }}</strong></td>
              </tr>
            </table>
          </div>
        </b-col>

        <!-- Website logo image -->
        <b-col cols="12" class="p-0" order="1" order-md="2">
          <div class="website-logo">
            <b-img
              v-if="websiteImgSrc"
              :src="websiteImgSrc"
            />
          </div>
        </b-col>

        <!-- Bill to -->
        <b-col class="p-0" cols="12" md="6" order="4" order-md="3">
          <div v-if="billTo" class="x-pad ys-pad d-flex h-100">
            <div class="mr-4">
              Bill to
            </div>
            <div>
              <strong> {{ companyName }} </strong>
              <div class="pt-1">{{ `${billTo.firstName} ${billTo.lastName}` }}</div>
              <div>{{ billToAddress.line1 }}</div>
              <div>{{ billToAddress.line2 }}</div>
              <div>
                {{ billToAddress.city }},
                {{ billToAddress.stateProvinceRegion }}
                {{ billToAddress.zipPostalCode }}
              </div>
            </div>
          </div>
        </b-col>

        <!-- Services provided by -->
        <b-col class="p-0" cols="12" md="6" order="2" order-md="4">
          <div v-if="payTo" class="services-provided-by">
            <div class="pb-1 hide-small">
              Services Provided By
            </div>
            <strong>{{ payTo.url }}</strong>
            <div>{{ payTo.name }}</div>
            <div>{{ payTo.address.line1 }}</div>
            <div>{{ payTo.address.line2 }}</div>
            <div>
              {{ payTo.address.city }},
              {{ payTo.address.stateProvinceRegion }}
              {{ payTo.address.zipPostalCode }}
            </div>
            <div>{{ payTo.email.emailAddress }}</div>
            <div v-phone-number="payTo.phone.phoneNumber" />
          </div>
        </b-col>

        <!-- Invoice body -->
        <b-col class="p-0" cols="12" order="3" order-md="5">
          <div class="invoice-body d-flex flex-column">
            <!-- Invoice status banner -->
            <div v-if="statusBanner" class="past-due-banner w-100 text-center mb-2 py-1">
              {{ statusBanner }}
            </div>

            <!-- Line items table -->
            <div class="w-100 line-items-table">
              <table>
                <tr>
                  <th>Description </th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
                <tr v-for="lineItem in invoiceLineItems" :key="lineItem.id">
                  <td>
                    <div>
                      {{ lineItem.description }}
                    </div>
                    <div
                      v-for="(subDescriptionLine,index) in lineItem.subDescriptionLines"
                      :key="index"
                      class="pt-1 sub-description-text"
                    >
                      {{ subDescriptionLine }}
                    </div>

                    <div v-if="lineItem.productBundle && lineItem.showItems">
                      <div
                        v-for="item in lineItem.invoiceLineItems"
                        :key="item.id"
                        class="bundle-item-text pl-1 pt-1"
                      >
                        <div class="d-flex">
                          <div>&#x2022;</div>
                          <div class="pl-2">{{ item.description }}</div>
                        </div>
                        <div
                          v-for="(subDescriptionLine,index) in item.subDescriptionLines"
                          :key="index"
                          class="pl-3 sub-description-text"
                        >
                          <small>{{ subDescriptionLine }}</small>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="text-right">{{ formatPrice(lineItem.price) }}</td>
                  <td>{{ lineItem.quantity }}</td>
                  <td>{{ formatPrice(lineItem.price * lineItem.quantity) }}</td>
                </tr>
                <tr>
                  <td class="text-right" colspan="2"><strong>Total</strong></td>
                  <td class="total-price py-2" colspan="2">{{ formatPrice(invoice.total) }}</td>
                </tr>
              </table>
            </div>

            <!-- Payment paid info -->
            <div v-if="status === 'paid'" class="payment-paid-container ml-auto my-4">
              <div class="payment-paid-header px-3 py-2">PAID</div>
              <table>
                <tr><th /><th /></tr>
                <tr v-if="invoice.paidDate">
                  <td class="px-3 py-2">Date</td>
                  <td class="text-right px-3 py-2">{{ formatDate(invoice.paidDate) }}</td>
                </tr>
                <tr v-if="accountNickName">
                  <td class="px-3 pb-2">Nickname</td>
                  <td class="text-right px-3 pb-2">{{ accountNickName }}</td>
                </tr>
                <tr v-if="paymentMethodType">
                  <td class="px-3 pb-2">{{ paymentMethodType }}</td>
                  <td class="text-right px-3 pb-2">{{ paymentMethodEndsIn }}</td>
                </tr>
              </table>
            </div>
          </div>
        </b-col>

        <!-- Fine print -->
        <b-col class="p-0" cols="12" order="6">
          <div class="fine-print">
            <div v-if="payTo" class="mb-2">
              <strong> {{ payTo.name }}</strong>
              provides many services to help you focus on what your business does best.
              Prompt payment will help ensure your subscription service with us continues uninterrupted.
            </div>
            <div><strong>Have a question or need to cancel?</strong></div>
            <div>Log into your account at any time, or you can send us an email or give us a call.</div>
          </div>
        </b-col>
      </div>
    </b-row>
    <b-row>
      <invoice-additional-details
        v-if="hasAdditionalDetails"
        :invoice="invoice"
        class="mobile-friendly mx-auto"
        @hasDetails="setAdditionalDetails($event)"
      />
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { phoneNumber } from '@/directives/directives'
import FeatherIcon from '@/components/shared/FeatherIcon'
import InvoiceAdditionalDetails from '@/components/Invoice/InvoiceAdditionalDetails'
import Vue from 'vue'
import { formatDateStringFromUnix } from '@/common/modules/formatters'

export default {
  name: 'Invoice',
  components: { FeatherIcon, InvoiceAdditionalDetails },
  directives: {
    phoneNumber,
  },
  data() {
    return {
      currentPage: this.$route.params.page
        ? Number(this.$route.params.page)
        : 0,
      invoice: null,
      bus: new Vue(),
      hasAdditionalDetails: false,
      websiteImgSrc: null,
    }
  },
  computed: {
    status() {
      return this.invoice.status
    },
    invoiceLineItems() {
      return this.invoice.invoiceLineItems || []
    },
    payTo() {
      return this.invoice.contact
    },
    payToAddress() {
      return this.payTo?.billingAddress
    },
    billTo() {
      return this.invoice.recipient
    },
    billToAddress() {
      return this.billTo?.address
    },
    paymentInfo() {
      return this.invoice.paymentInfo
    },
    preventPayment() {
      return this.status !== 'unpaid'
    },
    companyName() {
      return this.invoice.companyName.replace(/&amp;/g, '&')
    },
    statusBanner(){
      const invoiceDueDate = new Date(this.invoice.dueDate * 1000)
      invoiceDueDate.setHours(23, 59, 59, 999)
      const status = this.status
      const pastDue = status === 'unpaid' && new Date() > invoiceDueDate && 'PAST DUE'
      const alternativeStatus = status !== 'paid' && status !== 'unpaid' && status.toUpperCase()
      return pastDue || alternativeStatus
    },
    paymentMethodEndsIn() {
      if (!this.paymentInfo || this.invoice.type === 'refund') return

      // Check
      if (this.paymentInfo.check && this.paymentInfo.id) {
        return `# ${this.paymentInfo.id}`
      }

      // Cards and ACH
      const endsIn = this.paymentInfo.check && this.paymentInfo.id
            || this.paymentInfo.card?.last4
            || this.paymentInfo.paymentMethod?.last4
            || this.paymentInfo.paymentMethod?.bankAccountNumber?.slice(-4).replace(/X/g, '*')

      return endsIn ? `ending in *${endsIn}` : null
    },
    paymentMethodType() {
      if (!this.paymentInfo) return

      if (this.invoice.type === 'refund') {
        return 'Refund'
      } else if (this.paymentInfo.check && this.paymentInfo.id){
        return 'Check'
      } else if (this.paymentInfo.card?.last4 || this.paymentInfo.paymentMethod?.last4) {
        return 'Card'
      } else if (this.paymentInfo.paymentMethod?.bankAccountNumber) {
        return 'Account'
      }
      return null
    },
    accountNickName() {
      return this.paymentInfo?.paymentMethod?.nickname
    },
  },
  async mounted() {
    await this.fetchInvoice()
    this.getLogoImgSrc()

    // Initialize facades
    const facades = []
    for (const item of _.cloneDeep(this.invoice.invoiceLineItems)) {
      if (item.invoiceFacade) {
        const current_facade = facades.find(x => x.id === item.invoiceFacade.id)
        if (!current_facade) {
          let invoiceFacade = item.invoiceFacade
          invoiceFacade.price = item.price
          invoiceFacade.quantity = 1
          invoiceFacade['invoiceLineItems'] = item.show ? [item] : []
          facades.push(invoiceFacade)
        } else {
          current_facade.price += item.price

          if (item.show) {
            current_facade['invoiceLineItems'].push(item)
          }
        }

        let index = this.invoice.invoiceLineItems.findIndex(x => x.id === item.id)
        this.invoice.invoiceLineItems.splice(index, 1)
      }
    }

    this.invoice.invoiceLineItems = this.invoice.invoiceLineItems.concat(facades)
  },
  methods: {
    async fetchInvoice(){
      this.invoice =  await this.$store.dispatch('invoices/fetchId', {
        id: this.$route.params.id,
      })
    },
    getLogoImgSrc(){
      const name = this.payTo?.url?.replace('www.', '')
      const src = `https://corptools-public-ui-assets.s3.us-west-2.amazonaws.com/websites/${name}.png`
      const img = new Image()
      img.src = src
      img.onload = () => {this.websiteImgSrc = src}
      img.onerror = () => {this.websiteImgSrc = null}
    },
    setAdditionalDetails(hasDetailsEvent) {
      this.hasAdditionalDetails = hasDetailsEvent
    },
    payInvoice() {
      this.$router.push({
        name: 'payInvoices',
        query: { invoiceIds: this.invoice.id },
      })
    },
    async download() {
      const result = await this.$store.dispatch('invoices/fetchURLS', {
        ids: [this.$route.params.id],
        origin: 'client',
      })
      if (result.length > 0) {
        if (result[0].url !== null) {
          window.open(result[0].url, '_blank')
        } else {
          this.$bvToast.toast('Error downloading PDF. Please try again later.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
            autoHideDelay: 3000,
          })
        }
      }
    },
    formatDate: formatDateStringFromUnix,
    formatPrice(value) {
      return this.$options.filters.currency(value)
    },
  },
}
</script>

<style lang="scss" scoped>
.button-section {
  width: 100%;
  border-bottom: 1px solid #C8C7C7;

  @media (min-width: 992px) {
    width: 85%;
  }
}

.invoice-container {
  width: 100%;
  border: 1px solid #C8C7C7;

  @media (min-width: 992px) {
    width: 85%;
    border-left: 25px solid #000864;
  }

  .hide-small{
    @media (max-width: 767px){
      display: none;
    }
  }

  .invoice-info {
    @extend .x-pad;
    @extend .y-pad;
    @media (min-width: 768px) {
      border-left: 1px solid #000864;
    }

    background-color: #F8F8F8;
    border-bottom: 1px solid #000864;

    .invoice-title-text {
      color: #000864;
      font-size: 1.75rem;
      font-weight: bold;
      line-height: 1;

      @media (min-width: 576px) {
        font-size: 4rem;
      }
    }

    table {
      width: 95%;
    }
  }

  .website-logo {
    @extend .x-pad;
    @extend .y-mg;
    width: 100%;
    vertical-align: middle;

    @media (min-width: 768px) {
      width: 50%;
      margin-left: auto;
    }

    img {
      max-height: 85px;
      max-width: 50%;
    }
  }

  .services-provided-by {
    @extend .x-pad;
    padding: 0 .15rem 0 .15rem;
    word-break: break-word;
  }

  .invoice-body {
    @extend .mobile-friendly;
    @extend .x-pad;
    @extend .y-mg;

    .past-due-banner {
      background-color: #000864;
      color: white;
      font-weight: bold;
      font-size: 1.0rem;
    }

    .line-items-table {
      .total-price {
        font-size: 1.7rem;
        font-weight: bold;
      }

      .sub-description-text {
        color: #5a5757;
        font-size: .95rem;
      }

      .bundle-item-text {
        font-size: .95rem;
      }

      table {
        width: 100%;

        th {
          text-align: right;
          padding: .25rem 1rem .25rem 1rem;
          background-color: #f1f1f2;
        }

        th:first-child {
          text-align: left;
        }

        td {
          border-bottom: 1px solid #c8c7c7;
          vertical-align: top;
          padding: .75rem 1rem .75rem 1rem;
          text-align: right;
        }

        td:first-child {
          min-width: 50%;
          text-align: left;
        }

        tr:last-child {
          td {
            vertical-align: middle;
            text-align: right;
            border-bottom: none;
          }
        }
      }
    }

    .payment-paid-container {
      border: 1px solid #000864;
      border-radius: 10px;
      width: 280px;

      @media (max-width: 575px) {
        width: 100%;
        border-radius: 0;
      }

      .payment-paid-header {
        background-color: #000864;
        border-radius: 8px 8px 0 0;
        color: white;
        font-weight: bold;
        font-size: 1.1rem;

        @media (max-width: 575px) {
          width: 100%;
          border-radius: 0;
        }
      }

      table {
        width: 100%;
        td {
          vertical-align: top;
        }
      }
    }
  }

  .fine-print {
    @extend .x-pad;
    @extend .y-pad;
    font-size: .65rem;
  }
}

.x-pad {
  padding-left: 1rem;
  padding-right: 1rem;
  @media (min-width: 992px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.y-pad {
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media (min-width: 992px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.ys-pad{
  @media (max-width: 767px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.y-mg {
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media (min-width: 992px) {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .mobile-friendly {
    padding: 0 !important;
  }
}
</style>
